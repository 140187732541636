import { NavLink } from "react-router-dom";

const ContainerWidget = (props) => {
  function goTop() {
    window.scrollTo(0, 0);
  }
  return (
    <div className="widget-container">
      <div className="widget-main">
        <div className="widget-text-container">
          <h1>{props.title}</h1>
          <p>{props.description}</p>
          <NavLink
            className="home-gallery-button-a"
            end
            to={props.url}
            onClick={goTop}
          >
            {props.btnText}
          </NavLink>
        </div>
        <NavLink className="main-a-pic" end to={props.url} onClick={goTop}>
          <div
            className="widget-img-container"
            style={{ backgroundImage: `url(${props.bgImg})` }}
          ></div>
        </NavLink>
        <div className="home-float-container"></div>
      </div>
    </div>
  );
};
export default ContainerWidget;