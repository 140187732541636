import "./App.css";
import "./Styles/main.scss";

import { HashRouter, Route, Routes } from "react-router-dom";
import Home from "./Views/home";
import Contact from "./Views/contact";

import HeaderMain from "./Components/header";
import Footer from "./Components/footer";
import Gallery_Backdrops from "./Views/gallery_Backdrops";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import {
  ANIMAL_BALLOONS,
  BACKDROP_IMAGES,
} from "./Pictures/GalleryImages";
import Gallery_Animals from "./Views/gallery_Animals";
import Gallery_Decorations from "./Views/gallery_Decorations";
import Gallery_Events from "./Views/gallery_Events";

function App() {
  const [selected, setSelected] = useState("us");
  const { t, i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(selected);
  }, [selected]);
  return (
    <div className="App">
      <HashRouter>
        <HeaderMain texts={t} selected={selected} setSelected={setSelected} />
        <Routes>
          <Route exact path="/" element={<Home texts={t} />} />

          <Route
            exact
            path="/animalBalloons"
            element={
              <Gallery_Animals
                gallerySource={ANIMAL_BALLOONS}
                title={t("GalleryTitle1")}
              />
            }
          />
          <Route
            exact
            path="/backdrops"
            element={
              <Gallery_Backdrops
                gallerySource={BACKDROP_IMAGES}
                title={t("GalleryTitle2")}
              />
            }
          />
          <Route
            exact
            path="/decorations"
            element={
              <Gallery_Decorations
                gallerySource={ANIMAL_BALLOONS}
                title={t("GalleryTitle3")}
              />
            }
          />
          <Route
            exact
            path="/events"
            element={
              <Gallery_Events
                gallerySource={ANIMAL_BALLOONS}
                title={t("GalleryTitle4")}
              />
            }
          />

          <Route exact path="/contact" element={<Contact />} />
        </Routes>
        <Footer texts={t} />
      </HashRouter>
    </div>
  );
}

export default App;