import { Fragment, useState, useEffect } from "react";
import ImageModal from "../Components/UI/ImageModal";
import GalleryBackdropTitle from "../Components/GalleryBackdropTitle";
import GoTopButton from "../Components/UI/GoTopButton";
function Gallery_Backdrops(props) {
  const URL =
    "https://man.aniaballoon.com/api/fetchGalleryPictures.php?gallery=backdrops";
  const [showImageModal, setShowImageModal] = useState(false);
  const [idOfImageToShowInModal, setIdOfImageToShowInModal] = useState("");

  const [pictureGalery, setPictureGallery] = useState(["name"]);
  useEffect(() => {
    fetch(URL)
      .then((resp) => resp.json())
      .then((respJson) => {
        setPictureGallery(respJson);
        respJson.forEach((element) => {});
      });
  }, []);

  const showImageHandler = (id) => {
    setIdOfImageToShowInModal(id);
    document.body.style.overflow = "hidden";
    setShowImageModal(true);
  };

  const hideImage = () => {
    setShowImageModal(false);
    document.body.style.overflow = "";
  };

  return (
    <Fragment>
      {showImageModal && (
        <ImageModal
          imagesArrey={pictureGalery.map((x) => x.name)}
          entryId={idOfImageToShowInModal}
          onHide={hideImage}
        />
      )}
      <GalleryBackdropTitle title={props.title} />
      <div className="grid-gallery">
        {pictureGalery.map((x, y) => (
          // <div className="pics" onClick={() => showImageHandler(y)} key={y}>
          <div className="grid-item ">
            <a onClick={() => showImageHandler(y)} key={y}>
              <img
                src={`https://man.aniaballoon.com/uploads/${x.name}`}
                alt={`${x.name}`}
              />
            </a>
          </div>
          // </div>
        ))}
      </div>
    </Fragment>
  );
}
export default Gallery_Backdrops;