import React from "react";
import ContactForm from "../Components/Contact";
import ContainerWidget from "../Components/ContainerWidget";
import ContainerWidgetVideo from "../Components/ContainerWidgetVideo";
import btnAnimals from "../Pictures/btnAnimals.jpg";
import btnBackdrops from "../Pictures/btnBackdrops.jpg";
import btnDecorations from "../Pictures/btnDecorations.jpg";
import btnEvents from "../Pictures/btnEvents.jpg";

const Home = (props) => {
  return (
    <div className="page-conatiner-box">
      <ContainerWidget
        url="/animalBalloons"
        bgImg={btnAnimals}
        btnText={props.texts("home_1")}
        title={props.texts("PageBalloons")}
        description={props.texts("HomeText2")}
      />
      <ContainerWidget
        url="/backdrops"
        bgImg={btnBackdrops}
        btnText={props.texts("home_2")}
        title={props.texts("PageBackdrop")}
        description={props.texts("HomeText3")}
      />
      <ContainerWidget
        url="/decorations"
        bgImg={btnDecorations}
        btnText={props.texts("home_3")}
        title={props.texts("PageDecorations")}
        description={props.texts("HomeText4")}
      />
      <ContainerWidget
        url="/events"
        bgImg={btnEvents}
        btnText={props.texts("home_4")}
        title={props.texts("PageEvents")}
        description={props.texts("HomeText5")}
      />
      <ContainerWidgetVideo />
      <ContactForm contactInfo={props.texts("contactText")} />
    </div>
  );
};
export default Home;